import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { ReactComponent as CloseSvg } from 'Assets/icons/book/close.svg';

import styles from './Popup.module.scss';

interface IProps {
  onClose: () => void;
  children: React.ReactNode;
  popupClassName?: string;
  wrapperClassName?: string;
}

const Popup = ({
  onClose,
  children,
  popupClassName,
  wrapperClassName,
}: IProps) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const modalContent = modalContentRef.current;
    if (modalContent) {
      const handleScroll = (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
      };

      modalContent.addEventListener('wheel', handleScroll);

      return () => {
        modalContent.removeEventListener('wheel', handleScroll);
      };
    }
  }, []);

  return (
    <div className={clsx(styles.popup, popupClassName)} ref={modalContentRef}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={clsx(styles.wrapper, wrapperClassName)}>
        <CloseSvg className={styles.closeIcon} onClick={onClose} />
        {children}
      </div>
    </div>
  );
};

export default Popup;
