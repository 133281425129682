import clsx from 'clsx';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { DispatchType, RootState } from 'store/rootReducer';
import { ReactComponent as Logo } from 'Assets/icons/changePageLogo.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as ViewsIcon } from 'Assets/icons/statsViews.svg';
import { ReactComponent as FingerIcon } from 'Assets/icons/finger.svg';
import { ReactComponent as PersonIcon } from 'Assets/icons/person.svg';
import { ReactComponent as SmartListIcon } from 'Assets/icons/textEditor/list-ul-solid.svg';
import { ReactComponent as DeleteIcon } from '../../Accounts/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../Accounts/icons/edit.svg';
import { ReactComponent as DuplicateIcon } from 'Assets/icons/duplicate.svg';
import { IBroadcast } from 'types';
import { DATA_URL } from 'Constants';
import OptionMenu from 'Components/OptionMenu/OptionMenu';

import styles from './BroadcastItem.module.scss';

enum MenuItems {
  DELETE,
  EDIT,
  DUPLICATE,
}

const menuItems = [
  {
    label: 'Edit',
    key: MenuItems.EDIT,
    icon: <EditIcon />,
  },
  {
    label: 'Duplicate',
    key: MenuItems.DUPLICATE,
    icon: <DuplicateIcon />,
  },
  {
    label: 'Delete',
    key: MenuItems.DELETE,
    icon: <DeleteIcon />,
  },
];

interface IBroadcastItemProps {
  item: IBroadcast;
  stats: { delivered: number; opened: number; clicked: number };
}

const BroadcastItem = (props: IBroadcastItemProps) => {
  const [url, setUrl] = useState<string>('');

  const history = useHistory();

  const handleNavigate = () => {
    // history.push(`/console/settings/broadcasts/${props.item._id}`);
  };

  return (
    <div className={styles.container} onClick={handleNavigate}>
      <div className={styles.image}>
        {props.item.image ? (
          <img
            className={styles.broadcastPreviewImage}
            src={`${DATA_URL}/${props.item.image}`}
            alt="preview"
          />
        ) : (
          <Logo className={styles.logo} />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.line}>
          <div className={styles.name}>{props.item.name}</div>
        </div>
        {props.item.sender?.name && (
          <div className={styles.line}>
            <PersonIcon fill="#a0a0a0" width={16} height={16} />
            <div className={styles.sender}>{props.item.sender?.name}</div>
          </div>
        )}
        {props.item.smartList?.name && (
          <div className={styles.line}>
            <SmartListIcon fill="#a0a0a0" width={16} height={16} />
            <div className={styles.list}>{props.item.smartList?.name}</div>
          </div>
        )}
      </div>
      <div className={styles.stats}>
        <div className={styles.stat}>
          <MailIcon />
          <div
            className={clsx(styles.statAmount, {
              [styles.emptyStat]: props.stats?.delivered === undefined,
            })}
          >
            {props.stats.delivered ?? '-'}
          </div>
        </div>
        <div className={styles.stat}>
          <ViewsIcon />
          <div
            className={clsx(styles.statAmount, {
              [styles.emptyStat]: props.stats?.opened === undefined,
            })}
          >
            {props.stats?.opened ?? '-'}
          </div>
        </div>
        <div className={styles.stat}>
          <FingerIcon />
          <div
            className={clsx(styles.statAmount, {
              [styles.emptyStat]: props.stats?.clicked === undefined,
            })}
          >
            {props.stats?.clicked ?? '-'}
          </div>
        </div>
      </div>
      <div className={styles.options}>
        {/* <OptionMenu menuProps={menuProps} /> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: DispatchType) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastItem);
