import TemplateCard from '../TemplateCard/TemplateCard';

import styles from './BroadcastTabContent.module.scss';

interface IProps {
  templates: any[];
}

const BroadcastTabContent = ({ templates }: IProps) => {
  return (
    <div className={styles.templateList}>
      {templates.map((template, index) => (
        <TemplateCard template={template} key={index} />
      ))}
    </div>
  );
};

export default BroadcastTabContent;
