import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './Node.module.scss';
import { ReactComponent as CornerIcon } from 'Assets/icons/corner.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as More } from 'Assets/icons/copyCraft/dots.svg';
import { ReactComponent as DeleteIcon } from 'Components/Accounts/icons/delete.svg';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import DropdownList from 'UILib/Dropdown/Dropdown';
import { Dropdown } from 'antd';

const operations = [
  { label: 'Whole', value: 'whole' },
  { label: 'Each Item', value: 'each' },
  { label: 'First Item', value: 'first' },
  { label: 'Middle Items', value: 'middle' },
  { label: 'Last Item', value: 'last' },
];

enum MenuItems {
  DELETE,
  EXPOSE,
}

export interface INode {
  id: string;
  name: string;
  type: string;
  width: number;
  height: number;
  x: number;
  y: number;
  connections: string[];
  variable: string | null;
  operation: string | null; //'whole' | 'each' | 'first' | 'middle' | 'last';
  prompt?: string;
}

export enum INodeEvents {
  Resize,
  Move,
  Create,
  Expose,
  Delete,
}

export interface INodeEvent {
  type: INodeEvents;
  sender: INode;
}

interface IProps {
  node: INode;
  children: JSX.Element[] | JSX.Element;
  onChange: (node: INode) => void;
  onEvent: (event: INodeEvent) => void;
  headerColor: string;
  icon: ReactNode;
}

const TemplateNode = (props: IProps) => {
  const handleMenuClick = (e: any) => {
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.EXPOSE: {
        props.onEvent({ type: INodeEvents.Expose, sender: props.node });
        break;
      }

      case MenuItems.DELETE: {
        props.onEvent({ type: INodeEvents.Delete, sender: props.node });
        break;
      }
    }
  };
  const menuProps = {
    items: [
      {
        label: 'Delete',
        key: MenuItems.DELETE,
        icon: <DeleteIcon />,
      },
      {
        label: 'Expose',
        key: MenuItems.EXPOSE,
        icon: <PlusIcon />,
      },
    ],
    onClick: handleMenuClick,
  };

  const handleNodeVariableChange = (value: string) => {
    value = value.replace(/\s+/g, '-'); //replace all spaces with dashes.
    const newNode = {
      ...props.node,
    };
    newNode.variable = value;
    props.onChange(newNode);
  };

  const handleNodeOperationChange = (value: string) => {
    props.node.operation = value;
    props.onChange(props.node);
  };

  return (
    <div
      className={styles.container}
      style={{
        width: props.node.width + 'px',
        left: props.node.x + 'px',
        top: props.node.y + 'px',
      }}
    >
      <div
        className={styles.header}
        style={{ backgroundColor: props.headerColor }}
        onMouseDown={() => {
          props.onEvent({ type: INodeEvents.Move, sender: props.node });
        }}
      >
        {props.icon}
        {props.node.name}
        <div className={styles.menuButtonContainer}>
          <Dropdown
            menu={menuProps}
            trigger={['click']}
            overlayClassName={clsx(styles.menu, 'custom')}
          >
            <div className={styles.menuButton}>
              <More />
            </div>
          </Dropdown>
        </div>
      </div>

      {props.node.operation && (
        <div className={styles.operation}>
          <div className={styles.operationLabel}>For</div>
          <DropdownList
            className={styles.operationsDropdown}
            type="stroke"
            label="Select Design"
            value={props.node.operation}
            size="small"
            options={operations}
            onChange={(newValue) =>
              handleNodeOperationChange(newValue as string)
            }
          />
        </div>
      )}
      {props.node.variable && (
        <div className={styles.variables}>
          #
          <EditableLabel
            size="small"
            value={props.node.variable}
            onChange={handleNodeVariableChange}
          />
        </div>
      )}

      <div className={styles.content} style={{ height: props.node.height }}>
        {props.children}

        <CornerIcon
          className={styles.resizeArea}
          onMouseDown={() => {
            props.onEvent({
              type: INodeEvents.Resize,
              sender: props.node,
            });
          }}
        />
      </div>

      <div className={styles.inputHandler} />

      <div
        className={styles.outputHandler}
        onMouseDown={() => {
          props.onEvent({ type: INodeEvents.Create, sender: props.node });
        }}
      >
        <PlusIcon />
      </div>
    </div>
  );
};

export default TemplateNode;
