export const CREATE_GENERATION_TEMPLATE_MUTATION = {
  operationName: 'createGenerationTemplate',
  query: `mutation createGenerationTemplate(
          $name: String!
          $actions: String!
          $layout: String!
        ) {
          createGenerationTemplate(
            name: $name
            actions: $actions
            layout: $layout
          ) {
            _id
            createdAt
            updatedAt
          }
        }`,
};
