import { connect } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from 'store/rootReducer';
import { fetchAllGenerationTemplatesAction } from 'store/generationTemplates/generationTemplatesActions';
import { IGenerationTemplate } from 'types';
import Button from 'UILib/Button/Button';
import queries from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';

interface IProps {
  fetchTemplates: () => void;
  loading: boolean;
  generationTemplates: IGenerationTemplate[];
}

const PageGenerationTemplates = (props: IProps) => {
  useEffect(() => {
    props.fetchTemplates();
  }, []);

  const handleCreateTemplate = async () => {
    try {
      graphQlCall({
        queryTemplateObject: queries.CREATE_GENERATION_TEMPLATE_MUTATION,
        values: { actions: '{}', layout: '[]', name: 'New Template' },
        headerType: 'USER-AUTH',
      }).then(() => {
        props.fetchTemplates();
      });

      // onDelete();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Button
        onClick={() => {
          handleCreateTemplate();
        }}
      >
        NEW TEMPLATE
      </Button>
      <>
        {props.generationTemplates.map((template) => {
          return (
            <div>
              {template._id}{' '}
              <a href={`/console/bookle/template/${template._id}/edit`}>EDIT</a>
            </div>
          );
        })}
      </>
      {props.generationTemplates.length === 0 && <div>NO TEMPLATES</div>}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  generationTemplates: state.generationTemplates.items,
  loading: state.generationTemplates.loading,
});

const mapDispatchToProps = {
  fetchTemplates: () => fetchAllGenerationTemplatesAction({ isLoading: false }),
  // deleteEpisode: (episodeId: string) => deletePodcastEpisodeAction(episodeId),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageGenerationTemplates);
