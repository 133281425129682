import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Socket } from 'socket.io-client';
import { graphQlCall } from 'graphql/utils';
import { RootState } from 'store/rootReducer';
import { IBookleTemplateEditor } from 'store/books/booksReducer';
import {
  updateBookleTemplateBlocks,
  updateBookleTemplateTextEditor,
} from 'store/books/booksActions';
import { createSocket, getToken } from 'utils/Utils';
import { capitalizeFirstLetter } from 'utils/helpers';
import { BookleTemplateBlock } from 'types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import queries from 'graphql/queries';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import EditHeader from 'Components/Common/EditHeader/EditHeader';
import TextEditorToolbar from 'Components/TextEditorToolbar/TextEditorToolbar';
import GenerationPreview from './GenerationPreview/GenerationPreview';
import TemplateView from './TemplateView/TemplateView';
import { INode } from './Nodes/Node';
import { INodeUserInput } from './Nodes/UserInput';
import { INodeGenerateImage } from './Nodes/GenerateImage';
import { INodeGenerateList } from './Nodes/GenerateList';
import { INodeGenerateText } from './Nodes/GenerateText';
import { IGenerationBlock } from './GenerationPreview/GenerationBlock/GenerationBlock';

import styles from './PageBookleTemplateEditor.module.scss';

interface IProps {
  bookleTemplateBlocks: BookleTemplateBlock[];
  templateTextEditor: IBookleTemplateEditor;
  updateTemplateBlocks: (payload: BookleTemplateBlock[]) => void;
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
}

const PageBookleTemplateEditor = ({
  bookleTemplateBlocks,
  templateTextEditor,
  updateTemplateBlocks,
  updateTextEditor,
}: IProps) => {
  const socket = useRef<Socket | null>(null);
  const { templateId } = useParams<{ templateId: string }>();

  const [generationTemplateData, setGenerationTemplateData] = useState<any>({});
  const [previewBlocks, setPreviewBlocks] = useState<IGenerationBlock[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  ///////////////////////refactoring area
  const [currentGenTaskId, setCurrentGenTaskId] = useState<string | null>(null);
  const [_tmp_generationData, _tmp_setGenerationData] = useState<any>({});
  const [_tmp_generationTaskId, _tmp_setGenerationTaskId] = useState();
  const [_tmp_nodes, _tmp_setNodes] = useState<any>({});
  /////////////////////////////////////////

  const ref = useRef<HTMLDivElement>(null);
  UseOnClickOutside(ref, (e) => {
    const textEditorElements = document.querySelectorAll(
      '[id^="text_editor_"]'
    );

    const clickedInsideTextEditor = Array.from(
      textEditorElements
    ).some((element) => element.contains(e.target as any));

    if (
      ref.current &&
      !ref.current.contains(e.target as any) &&
      !clickedInsideTextEditor
    ) {
      updateTextEditor({ editor: undefined, selection: undefined });
    }
  });

  const handleRedirectToTemplates = () => {};

  const handleTemplateSave = async () => {
    const actions = generateTemplateFromNodes();
    console.log('SAVING....');

    await graphQlCall({
      queryTemplateObject: queries.UPDATE_GENERATION_TEMPLATE_MUTATION,
      values: {
        id: templateId,
        name: generationTemplateData.name,
        actions: JSON.stringify(actions),
        layout: JSON.stringify(bookleTemplateBlocks),
      },
      headerType: 'USER-AUTH',
    });

    console.log('SAVE is complete');
  };

  useEffect(() => {
    if (!socket.current) {
      socket.current = createSocket();
      socket.current.on(
        'generating-task-info-response',
        handleGenerationTaskUpdates
      );
      socket.current.on('connect', () => {
        console.log('connected to server');

        if (currentGenTaskId) {
          subscribeOnGenerationTaskUpdates(currentGenTaskId);
        }
      });
    }
    loadGenerationTemplateData();

    return () => {
      if (socket.current) {
        socket.current.off(
          'generating-task-info-response',
          handleGenerationTaskUpdates
        );
      }
    };
  }, []);

  useEffect(() => {
    if (socket.current) {
      socket.current.on(
        'generating-task-info-response',
        handleGenerationTaskUpdates
      );
    }

    return () => {
      if (socket.current) {
        socket.current.off(
          'generating-task-info-response',
          handleGenerationTaskUpdates
        );
      }
    };
  }, [previewBlocks]);

  useEffect(() => {
    if (currentGenTaskId) {
      subscribeOnGenerationTaskUpdates(currentGenTaskId);
    }
  }, [currentGenTaskId]);

  const subscribeOnGenerationTaskUpdates = (taskId: string) => {
    if (socket.current) {
      socket.current.emit('generating-task-info', {
        taskId,
        token: getToken(),
      });
    }
  };

  const handleGenerationTaskUpdates = (payload: any) => {
    console.log('stuff is coming:', payload);
    // if (payload.action === 'task data was updated') {
    //   //TODO: need to get what content is updated. and remove 'response';
    //   const data = findDataByTag('response', payload.task.data.userInput); //TODO: remove "userInput" HARDCODE.
    //   if (data) {
    //     updateGenerationBlockContent('response', data);
    //   }
    // }
  };

  const findDataByTag = (tag: string, data: any): string | null => {
    for (const item in data) {
      if (item === tag) {
        return data[item].value;
      }
    }

    if (data['children'] !== undefined) {
      return findDataByTag(tag, data['children']);
    }

    return null;
  };

  const loadGenerationTemplateData = () => {
    setLoading(true);
    graphQlCall({
      queryTemplateObject: queries.GET_ONE_GENERATION_TEMPLATE,
      values: { id: templateId },
      headerType: 'USER-AUTH',
    })
      .then((data) => {
        setGenerationTemplateData(data);
        updateTemplateBlocks(data.layout);
        setLoading(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleNodeExposure = (node: INode) => {
    const block = {
      node: node,
    } as IGenerationBlock;
    setPreviewBlocks([...previewBlocks, block]);
  };

  const updateGenerationBlockContent = (tag: string, content: string) => {
    console.log('TEST:', previewBlocks);
    previewBlocks.forEach((block) => {
      console.log('block.node.variable:', block.node.variable);
      if (block.node.variable === tag) {
        block.content = content;
        console.log('set');
      }
    });

    setPreviewBlocks([...previewBlocks]);
  };

  const handleNodeVariableChange = (node: INode) => {
    const variableCurrentValue = node.variable; //NOTE: ?? why ??
    previewBlocks.forEach((block) => {
      if (block.node.id === node.id) {
        block.node.variable = variableCurrentValue;
      }
    });

    setPreviewBlocks([...previewBlocks]);
  };

  const handleDataChange = (nodes: any) => {
    //TODO: figure out another way of doing this dictionary processing
    let data: any = {};
    for (const key in nodes) {
      const node = nodes[key];
      if (node.type === 'UserInput') {
        const nodeUi = node as INodeUserInput;
        //capture variables
        for (const form of nodeUi.forms) {
          for (const input of form.fields) {
            data[input.id] = input.example;
          }
        }
      }
    }

    _tmp_setNodes(nodes);
    _tmp_setGenerationData(data);
  };

  const generateTemplateFromNodes = () => {
    let actions: any = {};
    const visitedNodes = new Set<string>();

    const serializeNode = (node: INode) => {
      const serializedNode: any = {
        id: node.variable ?? node.id,
        type: node.type,
      };

      if (node.type === 'UserInput') {
        serializedNode.forms = (node as INodeUserInput).forms.map((form) => ({
          name: form.name,
          type: form.name
            .split(' ')
            .map((word) => capitalizeFirstLetter(word))
            .join(''),
          variables: form.fields.map((field) => ({
            id: field.id,
            type: field.type,
            label: field.label,
          })),
        }));
      } else if (node.type === 'GenerateText') {
        serializedNode.variable = (node as INodeGenerateText).variable;
        serializedNode.prompt = (node as INodeGenerateText).prompt;
      } else if (node.type === 'GenerateList') {
        serializedNode.variable = (node as INodeGenerateList).variable;
        serializedNode.prompt = (node as INodeGenerateList).prompt;
      } else if (node.type === 'GenerateImage') {
        serializedNode.variable = (node as INodeGenerateImage).variable;
        serializedNode.prompt = (node as INodeGenerateImage).prompt;
      }

      serializedNode.metadata = {
        width: node.width,
        height: node.height,
        x: node.x,
        y: node.y,
      };

      if (node.connections.length > 0) {
        serializedNode.postFunctions = node.connections.map((connectionId) => {
          const connectedNode = _tmp_nodes[connectionId];
          return {
            [connectedNode.operation || 'each']: serializeNode(connectedNode),
          };
        });
      } else {
        serializedNode.postFunctions = [];
      }

      return serializedNode;
    };

    const traverseAndSerialize = (node: INode) => {
      if (visitedNodes.has(node.id)) return null;
      visitedNodes.add(node.id);
      return serializeNode(node);
    };

    for (const key in _tmp_nodes) {
      const node = _tmp_nodes[key];
      //TODO: using UserInput as a root NODE where all connections are processed. need to finalize and clear
      if (node.type !== 'UserInput') {
        continue;
      }
      const serializedNode = traverseAndSerialize(node);
      if (serializedNode) {
        actions = serializedNode;
      }
    }

    return actions;
  };

  const handleGenerationStart = async () => {
    console.log('GENERATION STARTED...');

    const task = await graphQlCall({
      queryTemplateObject: queries.CREATE_GENERATION_TASK_MUTATION,
      values: { templateId: templateId },
      headerType: 'USER-AUTH',
    });

    console.log('TASK:', task);

    //sett curent gen so it will be subscribed on updates from generation task
    setCurrentGenTaskId(task.task._id);

    const response = await graphQlCall({
      queryTemplateObject: queries.PUSH_DATA_TO_GENERATION_TASK_MUTATION,
      values: {
        taskId: task.task._id,
        data: JSON.stringify(_tmp_generationData),
        path: task.path + '.userInput',
      },
      headerType: 'USER-AUTH',
    });
    console.log('push data response: ', response);
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#d0d0d0" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div ref={ref} id="header">
        <EditHeader
          showConfirmButton={true}
          pageName={generationTemplateData?.name || ''}
          title="Template name"
          handleConfirm={handleTemplateSave}
          handleGoBack={handleRedirectToTemplates}
          buttonPlaceholder="Save"
          additionalButtons={
            <Button
              appearance="stroke"
              width={160}
              height={40}
              onClick={handleGenerationStart}
            >
              Preview
            </Button>
          }
          customHeaderPlace="right"
          showCustomHeader={!!templateTextEditor.editor}
          className={styles.header}
          customHeader={
            <TextEditorToolbar
              editor={templateTextEditor.editor}
              selection={templateTextEditor.selection}
              showListItems={false}
            />
          }
        />
      </div>
      <div className={styles.workbench}>
        <div className={styles.nodeCanvas}>
          <TemplateView
            templateActions={generationTemplateData.actions}
            onVariableChange={handleNodeVariableChange}
            onExpose={handleNodeExposure}
            onChange={handleDataChange}
          />
        </div>
        <div className={styles.generationPreview}>
          <GenerationPreview generationBlocks={previewBlocks} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateTextEditor: state.books.bookleTemplateTextEditor,
  bookleTemplateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateTemplateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageBookleTemplateEditor);
