import { useEffect, useLayoutEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { isAdmin, validateUser } from 'utils/Utils';
import { RootState } from 'store/rootReducer';
import { IUserDetails } from 'types';
import FreeCreditsPopup, {
  FreeCreditsPopupModes,
} from 'Components/FreeCreditsPopup/FreeCreditsPopup';
import NoSubscriptionPopup from 'Components/projects/popups/NoSubsctiprionPopup';
import DomainsAndSenders from 'Components/DomainsAndSenders/DomainsAndSenders';
import RemoveDomainPopup from 'Components/DomainsAndSenders/Domains/RemoveDomainPopup/RemoveDomainPopup';
import RemoveSenderPopup from 'Components/DomainsAndSenders/Senders/RemoveSenderPopup/RemoveSenderPopup';
import UpdatePlanPopup from 'Components/projects/popups/UpdatePlanPopup';
import BillingSettings from 'Components/BillingSettings/BillingSettings';
import AddSenderPopup from 'Components/DomainsAndSenders/Senders/AddSenderPopup/AddSenderPopup';
import DnsRecordPopup from 'Components/DomainsAndSenders/Senders/DnsRecordsPopup/DnsRecordPopup';
import AddDomainPopup from 'Components/DomainsAndSenders/Domains/AddDomainPopup/AddDomainPopup';
import ConnectAgency from 'Components/Accounts/ConnectAgency/ConnectAgency';
import AddCNAMEPopup from 'Components/DomainsAndSenders/Domains/AddCNAMEPopup/AddCNAMEPopup';
import ProductPopup from 'Components/Products/ProductPopup/ProductPopup';
import DeleteProductPopup from 'Components/Products/DeleteProductPopup';
import Domains from 'Components/Domains/Domains';
import Broadcasts from 'Pages/Broadcasts/Broadcasts';
import Products from 'Components/Products/Products';
import Billing from 'Components/Billing/Billing';
import Credits from 'Components/Credits/Credits';
import Zapier from 'Components/Zapier/Zapier';
import SideMenu from 'Components/SideMenu/SideMenu';
import burgerSvg from '../../Assets/icons/burger.svg';

import s from './PageSettings.module.scss';

interface IProps {
  userDetails: IUserDetails;
}

const PageSettings = (props: IProps) => {
  const { option } = useParams<{ option: string }>();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [openAoSubscriptionPopup, setOpenNoSubscriptionPopup] = useState(false);
  const [freeCreditsPopupVisible, setFreeCreditsPopupVisible] = useState(false);
  const [freeCreditsPopupMode, setFreeCreditsPopupMode] = useState(
    FreeCreditsPopupModes.Default
  );
  const [upgradePopupVisible, setUpgradePopupVisible] = useState(false);

  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1000px)');
    if (mediaQuery.matches) {
      setIsSidebarOpen(false);
      setIsMobile(true);
    } else {
      setIsSidebarOpen(true);
      setIsMobile(false);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsSidebarOpen(false);
        setIsMobile(true);
      } else {
        setIsSidebarOpen(true);
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const generateContent = (option?: string) => {
    switch (option) {
      case 'domains':
        return <Domains />;
      case 'domains-senders':
        if (isAdmin()) return <DomainsAndSenders />;
        break;
      case 'broadcasts':
        return <Broadcasts />;
      case 'billing-settings':
        return <BillingSettings />;
      case 'billing':
        return <Billing />;
      case 'zapier':
        return <Zapier />;
      case 'agency':
        return <ConnectAgency />;
      case 'products':
        return <Products />;
      default:
        return <Domains />;
    }
  };

  const handlePlanOpen = () => {
    setFreeCreditsPopupVisible(false);
    setUpgradePopupVisible(true);
  };

  const userAuthed = validateUser();
  if (!userAuthed) {
    return <Redirect to={{ pathname: '/console/login' }} />;
  }

  return (
    <div className={s.settingsPage}>
      <div
        className={
          isSidebarOpen
            ? s.settingNavigation
            : s.settingNavigation + ' ' + s.moveLeft
        }
      >
        <div
          className={s.sidebarToggleBtnBlock}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <img src={burgerSvg} alt="burger svg" />
        </div>
        <SideMenu />
        {props.userDetails.scopes &&
          props.userDetails.scopes.includes('BOOKLE_GENERAL_ACCESS') && (
            <Credits
              isSidebarOpen={isSidebarOpen}
              aiCredits={props.userDetails.aiCredits}
              onFreeCreditsClick={() => setUpgradePopupVisible(true)}
              onUpgradePlanClick={() => {
                setFreeCreditsPopupVisible(true);
                setFreeCreditsPopupMode(FreeCreditsPopupModes.Default);
              }}
            />
          )}
      </div>
      <UpdatePlanPopup
        open={upgradePopupVisible}
        onClose={() => setUpgradePopupVisible(false)}
      />
      {freeCreditsPopupVisible && (
        <FreeCreditsPopup
          invitationLink={`https://signup.bookle.ai?rid=${props.userDetails.owner}`}
          credits={props.userDetails.aiCredits}
          mode={freeCreditsPopupMode}
          onPlanOpen={handlePlanOpen}
          onClose={() => setFreeCreditsPopupVisible(false)}
        />
      )}
      <div
        className={
          isSidebarOpen ? s.content : s.content + ' ' + s.contentMoveLeft
        }
      >
        {generateContent(option)}
      </div>
      <DeleteProductPopup />
      <ProductPopup />
      <AddSenderPopup />
      <AddDomainPopup />
      <AddCNAMEPopup />
      <RemoveDomainPopup />
      <RemoveSenderPopup />
      <DnsRecordPopup />
      <NoSubscriptionPopup
        open={openAoSubscriptionPopup}
        onClose={() => setOpenNoSubscriptionPopup(false)}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageSettings);
