export const Colors = [
  'transparent',
  '#5cc2fc',
  '#79fce9',
  '#8cf85a',
  '#feef63',
  '#fd978f',
  '#fd97ca',
  '#c092e9',
  //
  '#1ca4fc',
  '#2fe6cf',
  '#65d643',
  '#fed846',
  '#fc6554',
  '#fc48a1',
  '#834fe0',
  //
  '#1177b8',
  '#1aaa8e',
  '#29af1d',
  '#fcad2a',
  '#eb261f',
  '#d12177',
  '#5e30b3',
  //
  '#084e7e',
  '#0c6c65',
  '#0d700f',
  '#f07220',
  '#b31a10',
  '#951453',
  '#36217a',
  //
  '#ffffff',
  '#d5d5d5',
  '#878787',
  '#535353',
  '#292929',
  '#1b1b1b',
  '#000000',
];
