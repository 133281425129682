import { useState, useEffect, ReactNode } from 'react';
import clsx from 'clsx';
import Input from 'UILib/Input/Input';

import styles from './SizeStyleControl.module.scss';

interface IProps {
  max: number;
  min: number;
  step: number;
  theme?: 'dark' | 'light';
  className?: string;
  inputClassName?: string;
  customArrows?: ReactNode;
  onChange: (value: string) => void;
  value: string;
  title?: string;
  postfix?: JSX.Element;
  prefix?: JSX.Element;
}

const SizeStyleControl = (props: IProps) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onValueChange = (e: any) => {
    if (
      Number(e.target.value).toFixed().length <= props.max.toString().length
    ) {
      props.onChange(e.target.value);
    }
  };

  return (
    <div
      className={clsx(styles.container, props.className, {
        [styles.darkContainer]: props.theme === 'dark',
      })}
    >
      <div className={styles.titleContainer}>
        {props.prefix}
        <div className={styles.title}>{props.title}</div>
      </div>
      <Input
        min={props.min}
        max={props.max}
        step={props.step}
        value={value}
        onChange={onValueChange}
        type="number"
        customArrows={props.customArrows}
        postfixIcon={props.postfix && props.postfix}
        postfixIconClassName={styles.postfixIconClassName}
        className={clsx(
          styles.input,
          props.theme && styles[props.theme],
          props.inputClassName
        )}
      />
    </div>
  );
};

export default SizeStyleControl;
