import { useEffect } from 'react';
import { Dropdown } from 'antd';
import clsx from 'clsx';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import TextArea from 'UILib/TextArea/TextArea';
import DragAndDrop from 'UILib/DragAndDrop/DragAndDrop';
import DropdownUILib from 'UILib/Dropdown/Dropdown';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as HeadlineIcon } from 'Assets/icons/Headline.svg';
import TemplateNode, { INode, INodeEvent } from './Node';

import styles from './UserInput.module.scss';

export interface ITemplateForm {
  name: string;
  fields: {
    label: string;
    type: string;
    id: string;
    example?: string;
  }[];
}

export interface INodeUserInput extends INode {
  forms: ITemplateForm[];
}

interface IProps {
  node: INodeUserInput;
  onChange: (node: INode) => void;
  onEvent: (event: INodeEvent) => void;
}

const UserInput = (props: IProps) => {
  useEffect(() => {
    let maxHeight = 1;
    props.node.forms.forEach((form) => {
      if (form.fields.length > maxHeight) {
        maxHeight = form.fields.length;
      }
    });
    props.node.height = 200 + 50 * maxHeight;
    props.onChange({ ...props.node });
  }, []);

  const findFieldById = (fieldId: string) => {
    for (const form of props.node.forms) {
      for (const field of form.fields) {
        if (field.id === fieldId) {
          return field;
        }
      }
    }
    return null;
  };

  const handleInputFieldChange = (value: string, fieldId: string) => {
    const field = findFieldById(fieldId);
    if (field) {
      field.example = value;
    }
    props.onChange(props.node);
    // console.log('handleInputFieldChange:', value, fieldId);
  };

  const getField = (field: { label: string; type: string; id: string }) => {
    switch (field.type) {
      case 'Input':
        return (
          <Input
            border="stroke"
            className={styles.inputField}
            placeholder={field.label}
            onChange={(e) => {
              handleInputFieldChange(e.target.value, field.id);
            }}
          />
        );
      case 'Textarea':
        return (
          <TextArea height={70} onChange={() => {}} placeholder={field.label} />
        );
      case 'Dropdown':
        return (
          <DropdownUILib
            size="small"
            options={[]}
            type="stroke"
            label={field.label}
            onChange={() => {}}
          />
        );
      default:
        return null;
    }
  };

  return (
    <TemplateNode
      node={props.node}
      onChange={props.onChange}
      onEvent={props.onEvent}
      headerColor="#4957D8"
      icon={<HeadlineIcon />}
    >
      <div className={styles.container}>
        {props.node.forms.map((form, index) => (
          <div
            key={index}
            className={clsx(styles.form, {
              [styles.rightBorder]: index !== props.node.forms.length - 1,
            })}
          >
            <EditableLabel
              className={styles.formName}
              size="medium"
              value={form.name}
              maxWidth={260}
              onChange={(newValue) => {
                form.name = newValue;
                const newNode = {
                  ...props.node,
                };
                props.onChange(newNode);
              }}
            />
            <DragAndDrop
              className={styles.dragAndDropContainer}
              droppableId={props.node.id + ` form ${index}`}
              elements={form.fields}
              draggableNodes={form.fields.map((field) => (
                <div className={styles.fieldContainer}>
                  <div className={styles.fieldInfo}>
                    <EditableLabel
                      value={field.label}
                      maxWidth={200}
                      onChange={(newValue) => {
                        field.label = newValue;
                        const newNode = {
                          ...props.node,
                        };
                        props.onChange(newNode);
                      }}
                    />
                    <div className={styles.fieldTag}>
                      #
                      <EditableLabel
                        value={field.id}
                        maxWidth={70}
                        onChange={(newValue) => {
                          field.id = newValue;
                          const newNode = {
                            ...props.node,
                          };
                          props.onChange(newNode);
                        }}
                      />
                    </div>
                  </div>
                  {getField(field)}
                </div>
              ))}
              onDragEnd={(orderedFields) => {
                form.fields = orderedFields;
                const newNode = {
                  ...props.node,
                };
                props.onChange(newNode);
              }}
            />
            <Dropdown
              trigger={['click']}
              className={styles.addFieldButton}
              overlayClassName={styles.addFieldDropdown}
              menu={{
                onClick: ({ key }) => {
                  form.fields.push({
                    label: key,
                    id: key.toLowerCase(),
                    type: key,
                  });
                  const newNode = {
                    ...props.node,
                  };
                  props.onChange(newNode);
                },
                items: [
                  {
                    key: 'Input',
                    label: 'Input Field',
                  },
                  {
                    key: 'Textarea',
                    label: 'Textarea Field',
                  },
                  {
                    key: 'Dropdown',
                    label: 'Dropdown Menu',
                  },
                ],
              }}
            >
              <Button
                appearance="stroke"
                prefixIcon={<PlusIcon />}
                width={100}
                height={30}
              >
                Add
              </Button>
            </Dropdown>
          </div>
        ))}
        <Button
          className={styles.addNewFormButton}
          prefixIcon={<PlusIcon />}
          appearance="solid"
          width={20}
          height={60}
          onClick={() => {
            props.node.forms.push({
              name: 'Form Title',
              fields: [],
            });
            const newNode = {
              ...props.node,
              width: props.node.width + 280,
            };
            props.onChange(newNode);
          }}
        />
      </div>
    </TemplateNode>
  );
};

export default UserInput;
