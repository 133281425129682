import { ReactComponent as ArrowDown } from 'Assets/icons/ArrowDown.svg';

import styles from './CustomArrows.module.scss';

interface IProps {
  onIncrement: () => void;
  onDecrement: () => void;
}

const CustomArrowsForSizes = ({ onIncrement, onDecrement }: IProps) => {
  return (
    <div className={styles.sizeInputCustomArrows}>
      <ArrowDown
        width={12}
        height={12}
        className={styles.arrowUp}
        onClick={onIncrement}
      />
      <ArrowDown width={12} height={12} onClick={onDecrement} />
    </div>
  );
};

export default CustomArrowsForSizes;
