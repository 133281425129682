import styles from './TemplateCard.module.scss';

interface IProps {
  template: any;
}

const TemplateCard = ({ template }: IProps) => {
  return (
    <div className={styles.templateCardContainer}>
      <img
        src={template.image}
        className={styles.templateCover}
        alt="template"
      />
      <span className={styles.templateName}>{template.name}</span>
    </div>
  );
};

export default TemplateCard;
