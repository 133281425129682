import { isAdmin } from 'utils/Utils';
import { IUserAccess } from 'types';
import { ReactComponent as CartSvg } from 'Assets/icons/cart.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as ZapierSvg } from 'Assets/icons/zapier.svg';
import { ReactComponent as DomainsSvg } from 'Assets/icons/domains.svg';
import { ReactComponent as BillingSvg } from 'Assets/icons/billing.svg';
import { ReactComponent as ProductsSvg } from 'Assets/icons/sideMenu/products.svg';
import { ReactComponent as OutreachPageSvg } from 'Assets/icons/outreach.svg';
import { ReactComponent as ChangePageBookSvg } from 'Assets/icons/changePageBook.svg';
import { ReactComponent as ChangePageThemeSvg } from 'Assets/icons/changePageTheme.svg';
import { ReactComponent as ChangePageWorldSvg } from 'Assets/icons/changePageWorld.svg';
import { ReactComponent as ChangePagePodcastSvg } from 'Assets/icons/changePodcast.svg';
import { ReactComponent as ChangePageContactsSvg } from 'Assets/icons/changePageContacts.svg';
import { ReactComponent as CoursesSVG } from 'Assets/icons/courses.svg';

export const pageTypes = [
  'projects',
  'books',
  'settings',
  'copy-craft',
  'contacts',
  'podcaster',
  'agency',
  'products',
  'outreach',
  'domains',
  'domains-senders',
  'mailchimp',
  'billing',
  'billing-settings',
  'zapier',
];

export const menuItems = (userAccess: IUserAccess, isAdmin: boolean) => [
  {
    key: 'projects',
    name: 'Funnels',
    icon: <ChangePageWorldSvg />,
    access: userAccess.funnel,
    redirectUrl: '/console/projects',
  },
  {
    key: 'books',
    name: 'Bookle',
    icon: <ChangePageBookSvg />,
    access: userAccess.bookle,
    redirectUrl: '/console/books',
  },
  {
    key: 'copy-craft',
    name: 'CopyCraft',
    icon: <ChangePageThemeSvg />,
    access: userAccess.copyCraft,
    redirectUrl: '/console/copy-craft',
  },
  {
    key: 'podcaster',
    name: 'Podcaster',
    icon: <ChangePagePodcastSvg />,
    access: userAccess.podcast,
    redirectUrl: '/console/podcaster',
  },
  {
    key: 'outreach',
    name: 'Outreach',
    icon: <OutreachPageSvg />,
    access: isAdmin,
    redirectUrl: '/console/outreach',
  },
  {
    key: 'contacts',
    name: 'Contacts',
    icon: <ChangePageContactsSvg />,
    access: userAccess.contact,
    redirectUrl: '/console/contacts',
  },
  {
    key: 'products',
    name: 'Products',
    icon: <ProductsSvg />,
    access: userAccess.funnel,
    redirectUrl: '/console/settings/products',
  },
];

export const submenuItems = {
  outreach: [
    {
      key: 'broadcasts',
      name: 'Broadcasts',
      icon: <MailIcon />,
      access: isAdmin(),
      redirectUrl: '/console/outreach/broadcasts',
    },
    {
      key: 'workflows',
      name: 'Workflows',
      icon: <CoursesSVG />,
      access: isAdmin(),
      redirectUrl: '/console/outreach/workflows',
    },
  ],
  settings: [
    {
      key: 'domains-senders',
      name: 'Domains & Senders',
      icon: <DomainsSvg />,
      access: isAdmin(),
      redirectUrl: '/console/settings/domains-senders',
    },
    {
      key: 'domains',
      name: 'Domains',
      icon: <DomainsSvg />,
      access: true,
      redirectUrl: '/console/settings/domains',
    },
    {
      key: 'billing-settings',
      name: 'Stripe',
      icon: <CartSvg />,
      access: true,
      redirectUrl: '/console/settings/billing-settings',
    },
    {
      key: 'billing',
      name: 'Billing',
      icon: <BillingSvg />,
      access: true,
      redirectUrl: '/console/settings/billing',
    },
    {
      key: 'zapier',
      name: 'Zapier',
      icon: <ZapierSvg />,
      access: true,
      redirectUrl: '/console/settings/zapier',
    },
  ],
};
