import {
  useRef,
  ReactNode,
  useEffect,
  HTMLAttributes,
  DetailedHTMLProps,
  useState,
} from 'react';

import styles from './toolbarManipulatorPopup.module.scss';

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  popupContent: ReactNode;
  toolbarHeight: number;
}

const ToolbarManipulatorPopup = ({ popupContent, toolbarHeight }: IProps) => {
  const divRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(false);

  useEffect(() => {
    if (!divRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsAtTop(
          entry.intersectionRatio >= 0 && entry.boundingClientRect.top <= 300
        );
      },
      {
        root: null,
        rootMargin: '0px 0px -100% 0px',
        threshold: [1],
      }
    );

    observer.observe(divRef.current);

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [divRef]);

  return (
    <div
      className={styles.container}
      ref={divRef}
      style={isAtTop ? { top: 50 } : { bottom: toolbarHeight + 10 }}
    >
      {popupContent}
    </div>
  );
};

export default ToolbarManipulatorPopup;
