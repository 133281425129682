import { connect } from 'react-redux';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { RootState } from 'store/rootReducer';
import { ReactComponent as Delete } from 'Assets/icons/removeIcon.svg';
import { BookleTemplateBlock } from 'types';
import { findItemById } from '../../utils';

import styles from './more.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
}

const More = ({ item, templateBlocks, updateBlocks }: IProps) => {
  const handleRemove = () => {
    const newArray = [...templateBlocks];
    findItemById(newArray, item?.id, true);
    updateBlocks(newArray);
  };

  return (
    <div className={styles.container}>
      <div className={styles.remove} onClick={handleRemove}>
        <Delete /> Remove
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(More);
