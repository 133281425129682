import { useEffect, useState } from 'react';
import { ReactComponent as ArrowRightIcon } from 'Assets/icons/arrowRight.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Link from 'UILib/Link/Link';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';
import BroadcastEditor from './BroadcastEditor/BroadcastEditor';

import styles from './BroadcastGenerator.module.scss';

const tones = [
  { label: 'Professional', value: 'professional' },
  { label: 'Semi-Professional', value: 'semi-professional' },
  { label: 'Casual', value: 'casual' },
  { label: 'Friendly', value: 'friendly' },
  { label: 'Inspirational', value: 'inspirational' },
  { label: 'Humorous', value: 'humorous' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Confident', value: 'confident' },
  { label: 'Empathetic', value: 'emphatic' },
  { label: 'Playful', value: 'playful' },
  { label: 'Authoritative', value: 'Authoritative' },
  { label: 'Custom', value: 'custom' },
];

type TemplateForm = {
  name: string;
  type: string;
  variables: {
    id: string;
    type: string;
    label: string;
  }[];
};

const BroadcastGenerator = () => {
  const [showEditor, setShowEditor] = useState(false);
  const [fetchingForms, setFetchingForms] = useState(true);
  const [templateForms, setTemplateForms] = useState<TemplateForm[]>([]);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [formValidationError, setFormValidationError] = useState(false);
  const [generationData, setGenerationData] = useState<
    Record<string, Record<string, string | number>>
  >({});

  const handleEditorRedirectBack = () => {
    setShowEditor(!showEditor);
  };

  const handleNextClick = () => {
    const form = templateForms[currentFormIndex];
    const formValues = generationData[form.type];
    for (const key in formValues) {
      if (!formValues[key]) {
        setFormValidationError(true);
        return;
      }
    }
    setFormValidationError(false);
    if (currentFormIndex < templateForms.length - 1) {
      setCurrentFormIndex(currentFormIndex + 1);
    } else {
      setShowEditor(true);
    }
  };

  const getFormElement = (
    form: string,
    element: {
      id: string;
      type: string;
      label: string;
    }
  ) => {
    const formData = generationData[form] ?? {};
    switch (element.type) {
      case 'Input':
        return (
          <Input
            border="stroke"
            className={styles.input}
            error={
              formValidationError && !formData[element.id]
                ? 'Value required'
                : undefined
            }
            value={formData[element.id]}
            onChange={(e) => {
              generationData[form][element.id] = e.target.value;
              setGenerationData(Object.assign({}, generationData));
            }}
          />
        );
      case 'Dropdown':
        return (
          <Dropdown
            type="stroke"
            label="Select"
            options={[]}
            value={formData[element.id]}
            hasErrors={formValidationError && !formData[element.id]}
            onChange={(newValue) => {
              generationData[form][element.id] = newValue;
              setGenerationData(Object.assign({}, generationData));
            }}
          />
        );
      case 'Textarea':
        return (
          <TextArea
            height={230}
            hasErrors={formValidationError && !formData[element.id]}
            value={formData[element.id]}
            onChange={(newValue) => {
              generationData[form][element.id] = newValue;
              setGenerationData(Object.assign({}, generationData));
            }}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    graphQlCall({
      queryTemplateObject: queries.GET_ONE_GENERATION_TEMPLATE,
      values: { id: '66e0452c6732f93731975503' }, // TODO: replace with actual id
      headerType: 'USER-AUTH',
    })
      .then((data) => setTemplateForms(data.actions.forms))
      .finally(() => setFetchingForms(false));
  }, []);

  useEffect(() => {
    if (templateForms.length > 0) {
      const form = templateForms[currentFormIndex];
      if (!generationData[form.type]) {
        const generationDataCopy = { ...generationData };
        generationDataCopy[form.type] = {};
        form.variables.forEach((variable) => {
          generationDataCopy[form.type][variable.id] = '';
        });
        setGenerationData(generationDataCopy);
      }
    }
  }, [currentFormIndex, templateForms]);

  if (fetchingForms) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#4957d8" />
      </div>
    );
  }

  if (showEditor) {
    return <BroadcastEditor handleGoBack={handleEditorRedirectBack} />;
  }

  return (
    <div className={styles.container}>
      <Link
        to="/console/outreach/broadcasts"
        className={styles.backLink}
        prefixIcon={<Back />}
      >
        Dashboard
      </Link>
      {templateForms.length > 0 && (
        <>
          <div className={styles.header}>
            <MailIcon />
            <div className={styles.title}>
              {templateForms[currentFormIndex].name}
            </div>
            <div className={styles.subtitle}>
              Describe and generate or skip this step and fill in your email
              from scratch
            </div>
          </div>
          <div className={styles.form}>
            {templateForms[currentFormIndex].variables.map((variable) => (
              <div key={variable.id} className={styles.formElement}>
                {variable.label}
                {getFormElement(templateForms[currentFormIndex].type, variable)}
              </div>
            ))}
          </div>
          <div className={styles.buttonContainer}>
            <Button appearance="stroke" onClick={() => setShowEditor(true)}>
              Skip this Step
            </Button>
            <Button
              appearance="solid"
              onClick={handleNextClick}
              postfixIcon={<ArrowRightIcon />}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default BroadcastGenerator;
