import React, { useEffect, useState } from 'react';
import {
  Editor,
  Transforms,
  Element as SlateElement,
  BaseSelection,
  Text,
} from 'slate';
import { ReactComponent as OrderedList } from 'Assets/icons/textEditor/list-ol-solid.svg';
import { ReactComponent as UnorderedList } from 'Assets/icons/textEditor/list-ul-solid.svg';
import { ReactComponent as AlignLeftOutlined } from 'Assets/icons/textEditor/align-left.svg';
import { ReactComponent as AlignCenterOutlined } from 'Assets/icons/textEditor/align-center.svg';
import { ReactComponent as AlignRightOutlined } from 'Assets/icons/textEditor/align-right.svg';
import { ReactComponent as BoldOutlined } from 'Assets/icons/textEditor/bold.svg';
import { ReactComponent as ItalicOutlined } from 'Assets/icons/textEditor/italic.svg';
import { ReactComponent as UnderlineOutlined } from 'Assets/icons/textEditor/underline.svg';
import { Button, Toolbar } from './components';
import {
  CustomElement,
  CustomText,
  LIST_TYPES,
  TEXT_ALIGN_TYPES,
} from './types';
import ColorSelector from 'Components/ColorSelector/ColorSelector';
import FontSelector from 'Components/FontSelector/FontSelector';
import SizeStyleControl from 'Components/SizeSelector/SizeStyleControls';
import LinksControls from 'Components/LinksControls/LinksControls';

const TextEditorToolbar = ({
  editor,
  selection,
  showListItems = true,
}: {
  editor: Editor | undefined;
  selection: BaseSelection | undefined;
  showListItems?: boolean;
}) => {
  const [currentColor, setCurrentColor] = useState('#000000');
  const [currentFontFamily, setCurrentFontFamily] = useState('Inter');
  const [size, setSize] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [lineHeight, setLineHeight] = useState<string>('');
  const [currentLink, setCurrentLink] = useState<string>('');
  const [activeAlign, setActiveAlign] = useState<string>('left');
  const [activeList, setActiveList] = useState<string>('');

  useEffect(() => {
    setCurrentTextAttributes();
  }, [selection, editor]);

  const setCurrentTextAttributes = () => {
    if (!editor || !editor.selection) {
      setDefaults();
      return;
    }

    setCurrentColor(getCurrentAttribute('color', '#000000'));
    setCurrentFontFamily(getCurrentAttribute('font', 'Inter'));
    setSize(getCurrentAttribute('fontSize', '16'));
    setLineHeight(getCurrentAttribute('lineHeight', '1.5'));
    setWeight(getCurrentAttribute('weight', '400'));
    setCurrentLink(getCurrentAttribute('link', ''));
  };

  const setDefaults = () => {
    setCurrentColor('#000000');
    setCurrentFontFamily('Inter');
    setSize('16');
    setLineHeight('1.5');
    setWeight('400');
  };

  if (!editor) return null;

  const getCurrentAttribute = (
    attribute: keyof CustomText,
    defaultValue: string
  ) => {
    const [match] = Editor.nodes<CustomText>(editor, {
      at: editor.selection as any,
      match: (n) => Text.isText(n) && !!(n as CustomText)[attribute],
    });
    return match
      ? (match[0][attribute] as string) || defaultValue
      : defaultValue;
  };

  const toggleBlock = (format: string) => {
    const isActive = isBlockActive(
      format,
      (TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type') as any
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) => LIST_TYPES.includes((n as CustomElement).type),
      split: true,
    });

    let newProperties: Partial<SlateElement>;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = { align: isActive ? undefined : format };
    } else if (isList) {
      newProperties = { type: isActive ? 'paragraph' : 'list-item' };
    } else {
      newProperties = { type: isActive ? 'paragraph' : (format as any) };
    }

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block: any = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const toggleMark = (format: string) => {
    const isActive = isMarkActive(format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const applyTextStyle = (type: string, value: string | number) => {
    if (!editor.selection) return;

    Transforms.setNodes<CustomText>(
      editor,
      { [type]: value },
      { match: (n) => Text.isText(n), split: true }
    );
  };

  const isBlockActive = (format: string, blockType: keyof SlateElement) => {
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n as CustomElement)[blockType] === format,
      })
    );

    return !!match;
  };

  const isMarkActive = (format: string) => {
    const marks = Editor.marks(editor);

    return marks ? (marks as any)[format] === true : false;
  };

  const BlockButton: React.FC<{ format: string; icon: JSX.Element }> = ({
    format,
    icon,
  }) => {
    useEffect(() => {
      if (TEXT_ALIGN_TYPES.includes(format)) {
        if (isBlockActive(format, 'align' as any)) {
          setActiveAlign(format);
        }
      } else {
        setActiveList(isBlockActive(format, 'type' as any) ? format : '');
      }
    }, [format]);

    return (
      <Button
        active={
          TEXT_ALIGN_TYPES.includes(format)
            ? activeAlign === format
            : activeList === format
        }
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          toggleBlock(format);
          setActiveAlign(format);
        }}
      >
        {icon}
      </Button>
    );
  };

  const MarkButton: React.FC<{ format: string; icon: JSX.Element }> = ({
    format,
    icon,
  }) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    useEffect(() => {
      const active = setIsActive(isMarkActive(format));
      if (selection && typeof active === 'boolean') {
        setIsActive(active);
      }
    }, [format]);

    return (
      <Button
        active={isActive}
        onMouseDown={(event: React.MouseEvent) => {
          event.preventDefault();
          toggleMark(format);
          setIsActive(!isActive);
        }}
      >
        {icon}
      </Button>
    );
  };

  return (
    <Toolbar>
      <ColorSelector
        onChange={(color) => {
          applyTextStyle('color', color);
          setCurrentColor(color);
        }}
        color={currentColor}
      />
      <FontSelector
        select={currentFontFamily}
        onChange={(value) => {
          applyTextStyle('font', value);
          setCurrentFontFamily(value);
        }}
      />
      <SizeStyleControl
        step={1}
        max={200}
        min={0}
        value={size}
        onChange={(value) => {
          applyTextStyle('fontSize', Number(value));
          setSize(value);
        }}
      />
      <SizeStyleControl
        step={0.1}
        max={10}
        min={0}
        value={lineHeight}
        onChange={(value) => {
          applyTextStyle('lineHeight', Number(value));
          setLineHeight(value);
        }}
      />
      <SizeStyleControl
        step={100}
        max={900}
        min={0}
        value={weight}
        onChange={(value) => {
          applyTextStyle('weight', Number(value));
          setWeight(value);
        }}
      />
      <MarkButton format="bold" icon={<BoldOutlined />} />
      <MarkButton format="italic" icon={<ItalicOutlined />} />
      <MarkButton format="underline" icon={<UnderlineOutlined />} />
      {showListItems && (
        <>
          <BlockButton format="number-list" icon={<OrderedList />} />
          <BlockButton
            format="bulleted-list"
            icon={<UnorderedList fill="black" />}
          />
        </>
      )}
      <BlockButton format="left" icon={<AlignLeftOutlined />} />
      <BlockButton format="center" icon={<AlignCenterOutlined />} />
      <BlockButton format="right" icon={<AlignRightOutlined />} />
      <LinksControls
        onChange={(value) => {
          applyTextStyle('link', value);
          setCurrentLink(value);
        }}
        value={currentLink}
      />
    </Toolbar>
  );
};

export default TextEditorToolbar;
