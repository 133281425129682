import Tabs from 'Components/Templates/Tabs/Tabs';
import BroadcastTabContent from '../BroadcastTabContent/BroadcastTabContent';
import { ReactComponent as Arrow } from 'Assets/icons/ArrowDown.svg';

import styles from './BroadcastSideMenu.module.scss';

// this image url used as mock data, it will be removed as soon as
// there will be the right data
const mockImageUrl =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvmrZzt0z8rEW2ytm9PItbhHYnux_yge6O4V_hot97eKmLM0oIq7ZMfVh5gG0I9Yiy1Zo&usqp=CAU';

const mocklibTemplates = [
  { image: mockImageUrl, name: 'Text' },
  { image: mockImageUrl, name: 'Text A' },
  { image: mockImageUrl, name: 'Text A' },
  { image: mockImageUrl, name: 'Text A' },
  { image: mockImageUrl, name: 'Text A' },
  { image: mockImageUrl, name: 'Text A' },
  { image: mockImageUrl, name: 'Text A' },
];

const ownTemplates = [
  { image: mockImageUrl, name: 'Text' },
  { image: mockImageUrl, name: 'Text A' },
  { image: mockImageUrl, name: 'Text' },
];

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const BroadcastSideMenu = ({ isOpen, onClose }: IProps) => {
  return (
    <div className={styles.BroadcastSideMenu}>
      <div className={styles.container}>
        <Tabs
          className={styles.tabsWrapper}
          tabsHeader={styles.tabsHeader}
          contentClassName={styles.contentContainer}
          titleClassName={styles.titleContainer}
          hideAdditionalLines
          tabs={[
            {
              title: 'Library',
              content: <BroadcastTabContent templates={mocklibTemplates} />,
            },
            {
              title: 'Your Own',
              content: <BroadcastTabContent templates={ownTemplates} />,
            },
          ]}
        />
      </div>
      {isOpen && (
        <div className={styles.toggleContainer}>
          <div className={styles.toggle} onClick={onClose}>
            <Arrow className={styles.arrowIcon} />
          </div>
        </div>
      )}
    </div>
  );
};

export default BroadcastSideMenu;
