import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IBroadcast } from 'types';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import BroadcastItem from 'Components/Broadcast/BroadcastItem/BroadcastItem';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';

import styles from './Broadcasts.module.scss';

const sortCriteria = [{ label: 'Last Update', value: 'last_update' }];

const Broadcasts = () => {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState<string>();
  const [sortCriterion, setSortCriterion] = useState<string>('last_update');
  const [broadcasts, setBroadCasts] = useState<IBroadcast[]>([]);
  const [broadcastsStats, setBroadcastsStats] = useState<
    { delivered: number; opened: number; clicked: number }[]
  >([]);

  useEffect(() => {
    graphQlCall({
      queryTemplateObject: queries.GET_USER_BROADCASTS,
      headerType: 'USER-AUTH',
    }).then(setBroadCasts);
  }, []);

  useEffect(() => {
    if (broadcasts.length === 0) return;

    graphQlCall({
      queryTemplateObject: queries.GET_BROADCASTS_STATISTICS,
      values: { ids: broadcasts.map((item) => item._id) },
      headerType: 'USER-AUTH',
    }).then(setBroadcastsStats);
  }, [broadcasts]);

  return (
    <div className={styles.container}>
      <DashboardHeader
        total={broadcasts.length + ' Broadcasts'}
        title="Broadcasts"
        buttonText="New Broadcast"
        handleAddNewItem={() => history.push('/console/broadcasts/create')}
        handleSearch={() => {}}
        handleSort={() => {}}
        sortOptions={sortCriteria}
      />
      <div className={styles.broadcastElements}>
        {broadcasts.map((item, index) => (
          <BroadcastItem
            key={item._id}
            item={item}
            stats={broadcastsStats[index] ?? {}}
          />
        ))}
      </div>
    </div>
  );
};

export default Broadcasts;
