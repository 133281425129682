import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { ReactComponent as Duplicate } from 'Assets/icons/duplicate3.svg';
import { ReactComponent as MoreIcon } from 'Assets/icons/more.svg';
import { ReactComponent as Move } from 'Assets/icons/move2.svg';
import { ReactComponent as Settings } from 'Assets/icons/contextMenu/settingsIcon.svg';
import { findAndDuplicate } from '../utils';
import { BookleTemplateBlock } from 'types';
import { MenuItems } from '../Sidebar/Sidebar';
import ButtonBlockStylesContainer from './ButtonBlockStylesContainer/ButtonBlockStylesContainer';
import ContainerStyleBlock from './ContainerStyleBlock/ContainerStyleBlock';
import ImageBlockStyleContainer from './ImgageBlockStyleContainer/ImageBlockStyleContainer';
import DividerBlockStyleContainer from './DividerBlockStyleContainer/DividerBlockStyleContainer';
import ToolbarManipulatorPopup from '../toolbarManipulatorPopup/toolbarManipulatorPopup';
import More from './toolbarMore/more';

import styles from './toolbar.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  setDragging: (dragging: boolean) => void;
}

const Toolbar = ({
  item,
  templateBlocks,
  updateBlocks,
  setDragging,
}: IProps) => {
  const [toolbarType, setToolbarType] = useState<
    'settings' | 'more' | undefined
  >(undefined);
  const divRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(false);
  const [toolbarHeight, setToolbarTopHeight] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setToolbarTopHeight(entry.boundingClientRect.height);
        setIsAtTop(
          entry.intersectionRatio >= 0 && entry.boundingClientRect.top <= 80
        );
      },
      {
        root: null,
        rootMargin: '0px 0px -100% 0px',
        threshold: [1],
      }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [divRef]);

  const handleDuplicate = () => {
    setToolbarType(undefined);
    const newArray = [...templateBlocks];
    findAndDuplicate(newArray, item?.id);
    updateBlocks(newArray);
  };

  const handleMoveStart = () => {
    setToolbarType(undefined);
    setIsDragging(true);
    setDragging(true);
  };

  const handleMoveEnd = () => {
    setIsDragging(false);
    setDragging(false);
  };

  const onClickToolbarButton = (key: 'settings' | 'more') => {
    setToolbarType(toolbarType === key ? undefined : key);
  };

  return (
    <div
      className={`${styles.container} ${isDragging ? styles.dragging : ''}`}
      ref={divRef}
      style={isAtTop ? { top: 'initial', bottom: -40 } : {}}
      id={`toolbar-${item.id}`}
    >
      {!!toolbarType && (
        <ToolbarManipulatorPopup
          toolbarHeight={toolbarHeight}
          popupContent={
            toolbarType === 'more' ? (
              <More item={item} />
            ) : toolbarType === 'settings' ? (
              item.type === MenuItems.TEXT_BLOCK ? (
                <ContainerStyleBlock item={item} />
              ) : item.type === MenuItems.BUTTON_BLOCK ? (
                <ButtonBlockStylesContainer item={item} />
              ) : item.type === MenuItems.IMAGE_BLOCK ? (
                <ImageBlockStyleContainer item={item} />
              ) : item.type === MenuItems.DIVIDER_BLOCK ? (
                <DividerBlockStyleContainer item={item} />
              ) : null
            ) : null
          }
        />
      )}
      <div className={styles.toolbarItems}>
        <div className={styles.iconContainer} onClick={handleDuplicate}>
          <Duplicate />
        </div>
        <div
          className={styles.iconContainer}
          onMouseDown={handleMoveStart}
          onMouseUp={handleMoveEnd}
          onMouseLeave={handleMoveEnd}
          onClick={() => setToolbarType(undefined)}
        >
          <Move />
        </div>
        <div
          className={clsx(styles.iconContainer, {
            [styles.activeButton]: toolbarType === 'settings',
          })}
          onClick={() => onClickToolbarButton('settings')}
        >
          <Settings />
        </div>
        <div
          className={clsx(styles.iconContainer, {
            [styles.activeButton]: toolbarType === 'more',
          })}
          onClick={() => onClickToolbarButton('more')}
        >
          <MoreIcon />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
