import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SideMenu from 'Components/SideMenu/SideMenu';
import Workflows from 'Pages/Workflows/Workflows';
import Broadcasts from 'Pages/Broadcasts/Broadcasts';

import styles from './Outreach.module.scss';

const outreachProjects: Record<string, ReactNode> = {
  broadcasts: <Broadcasts />,
  workflows: <Workflows />,
};

const Outreach = () => {
  const location = useLocation();

  const [activeProject, setActiveProject] = useState('broadcasts');

  useEffect(() => {
    Object.keys(outreachProjects).forEach((project) => {
      if (location.pathname.includes(project)) {
        setActiveProject(project);
      }
    });
  }, [location.pathname]);

  return (
    <div className={styles.pageWrapper}>
      <SideMenu />
      {outreachProjects[activeProject]}
    </div>
  );
};

export default Outreach;
