import { Editor } from 'slate';
import { ENVIRONMENT, PAGECRAFT_API_URL } from 'Constants';
import { IGeneralProject } from 'types';
import { getUserId } from './Utils';

export const checkSpecialCharacters = (text: string) => {
  const regex = /[!@#$%^~` &*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  return regex.test(text);
};

export const toLocalISOString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60000);
  return localDate.toISOString().slice(0, -1) + formatOffset(offset);
};

const formatOffset = (minutes: number) => {
  const sign = minutes > 0 ? '-' : '+';
  const absOffset = Math.abs(minutes);
  const hours = Math.floor(absOffset / 60);
  const mins = absOffset % 60;
  return `${sign}${padZero(hours)}:${padZero(mins)}`;
};

const padZero = (number: number) => {
  return number < 10 ? '0' + number : number.toString();
};

export const truncateString = (string: string | undefined, maxLength = 70) => {
  if (!string) return '';
  return string.length > maxLength
    ? string.substring(0, maxLength) + '...'
    : string;
};

export const capitalizeFirstLetter = (word: string) => {
  if (word.length > 0) {
    return word[0].toUpperCase() + word.slice(1);
  }
  return word;
};

export const generateProjectUrl = (
  project: IGeneralProject,
  connectedDomain?: { name: string }
) => {
  let rootDomain = 'autofunnel.ai';
  if (ENVIRONMENT === 'DEV') {
    rootDomain = 'dev.autofunnel.ai';
  }
  if (connectedDomain) {
    return `https://${connectedDomain.name}`;
  }

  if (project.customSubDomain) {
    return `https://${project.customSubDomain}.${rootDomain}`;
  }

  return `https://live.${rootDomain}/${project._id}`;
};

export const isHotkey = (
  event: React.KeyboardEvent<HTMLDivElement>,
  hotkey: string
) => {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const isModKey = isMac ? event.metaKey : event.ctrlKey;

  return isModKey && event.key.toLowerCase() === hotkey;
};

const isMarkActive = (format: string, editor: Editor) => {
  const marks = Editor.marks(editor);

  return marks ? (marks as any)[format] === true : false;
};

const HOTKEYS: { [key: string]: string } = {
  b: 'bold',
  i: 'italic',
  u: 'underline',
};

const toggleMark = (format: string, editor: Editor) => {
  const isActive = isMarkActive(format, editor);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const onTextKeyDown = (
  event: React.KeyboardEvent<HTMLDivElement>,
  editor: Editor
) => {
  for (const hotkey in HOTKEYS) {
    if (isHotkey(event, hotkey)) {
      event.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(mark, editor);
    }
  }
};

export const uploadFile = (
  file: Blob,
  callback: (value: string | ArrayBuffer | null) => void
) => {
  const formData = new FormData();
  formData.append('field', JSON.stringify({ id: getUserId() }));
  formData.append('file', file);

  fetch(`${PAGECRAFT_API_URL}/images`, {
    method: 'POST',
    body: formData,
  })
    .then((r) => r.json())
    .then((data) => {
      callback(data.url);
    });
};
