import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { IBookleTemplateEditor } from 'store/books/booksReducer';
import { updateBookleTemplateTextEditor } from 'store/books/booksActions';
import Button from 'UILib/Button/Button';
import BroadcastSideMenu from './BroadcastSideMenu/BroadcastSideMenu';
import EditHeader from 'Components/Common/EditHeader/EditHeader';
import TextEditorToolbar from 'Components/TextEditorToolbar/TextEditorToolbar';
import GenerationPreview from 'Pages/PageBookleTemplateEdior/GenerationPreview/GenerationPreview';
import { IGenerationBlock } from 'Pages/PageBookleTemplateEdior/GenerationPreview/GenerationBlock/GenerationBlock';
import { BroadcastMenuType } from 'Pages/Broadcasts/types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { ReactComponent as ArrowRight } from 'Assets/icons/arrowRight.svg';
import { ReactComponent as Eye } from 'Assets/icons/eye.svg';
import { ReactComponent as Email } from 'Assets/icons/email.svg';
import { ReactComponent as Episodes } from 'Assets/icons/episodes.svg';
import EmailTestModal from 'Components/EmailTestModal/EmailTestModal';

import styles from './BroadcastEditor.module.scss';

interface IProps {
  handleGoBack: () => void;
  templateTextEditor: IBookleTemplateEditor;
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
}

const BroadcastEditor = ({
  handleGoBack,
  templateTextEditor,
  updateTextEditor,
}: IProps) => {
  const [showSideMenu, setShowSideMenu] = useState<BroadcastMenuType | boolean>(
    false
  );
  const [showTestEmailModal, setShowTestEmailModal] = useState<boolean>(false);

  const [previewBlocks, setPreviewBlocks] = useState<IGenerationBlock[]>([]);

  const ref = useRef<HTMLDivElement>(null);
  UseOnClickOutside(ref, (e) => {
    const textEditorElements = document.querySelectorAll(
      '[id^="text_editor_"]'
    );

    const clickedInsideTextEditor = Array.from(
      textEditorElements
    ).some((element) => element.contains(e.target as any));

    if (
      ref.current &&
      !ref.current.contains(e.target as any) &&
      !clickedInsideTextEditor
    ) {
      updateTextEditor({ editor: undefined, selection: undefined });
    }
  });

  const toggleSideMenu = (menuType?: BroadcastMenuType) => {
    if (showSideMenu) {
      setShowSideMenu(false);
    }
    setShowSideMenu(menuType || false);
  };

  const handleSave = () => {
    // we will have api call to save the template
  };

  const handleShowPreview = () => {
    // logic to show the preview
  };

  const handleTestEmail = () => {
    setShowTestEmailModal(true);
  };

  const handleDraggableSidebarEvent = (isSidebarOpen: boolean) => {
    if (isSidebarOpen) {
      setShowSideMenu(BroadcastMenuType.draggableComponentMenu);
      return;
    }
    setShowSideMenu(false);
  };

  return (
    <>
      <EmailTestModal
        open={showTestEmailModal}
        onClose={() => setShowTestEmailModal(false)}
      />
      <div className={styles.BroadCastEditor}>
        {!showSideMenu && (
          <div className={styles.buttonsContainer}>
            <Button
              appearance="solid"
              width={40}
              height={40}
              className={styles.menuButton}
              onClick={() => toggleSideMenu(BroadcastMenuType.templateMenu)}
            >
              <Episodes />
            </Button>
          </div>
        )}
        <div ref={ref} id="header">
          <EditHeader
            showConfirmButton={true}
            pageName={'This is your last chance'}
            title="Subject"
            handleConfirm={handleSave}
            handleGoBack={handleGoBack}
            buttonPlaceholder={
              <div className={styles.confirmButtonPlaceholder}>
                Save & Continue
                <ArrowRight
                  className={styles.placeholderIcon}
                  width={22}
                  height={18}
                />
              </div>
            }
            additionalButtons={
              <div className={styles.additionalButtons}>
                <Button
                  appearance="stroke"
                  height={40}
                  prefixIcon={
                    <div className={styles.emailPrefixContainer}>
                      Test Email
                      <Email
                        className={styles.ghostIcon}
                        width={22}
                        height={18}
                      />
                    </div>
                  }
                  onClick={handleTestEmail}
                />
                <Button
                  appearance="stroke"
                  width={60}
                  height={40}
                  prefixIcon={
                    <Eye className={styles.ghostIcon} width={22} height={18} />
                  }
                  onClick={handleShowPreview}
                />
              </div>
            }
            showCustomHeader={!!templateTextEditor.editor}
            customHeader={
              <TextEditorToolbar
                editor={templateTextEditor.editor}
                selection={templateTextEditor.selection}
                showListItems={false}
              />
            }
          />
        </div>
        <div className={styles.EditorContainer}>
          <div
            className={`${styles.SideMenu} ${
              showSideMenu !== BroadcastMenuType.templateMenu
                ? styles.collapsed
                : ''
            }`}
          >
            <BroadcastSideMenu
              isOpen={!!showSideMenu}
              onClose={() => toggleSideMenu()}
            />
          </div>
          <div className={styles.Editor}>
            <GenerationPreview
              generationBlocks={previewBlocks}
              isSidebarHidden={showSideMenu === BroadcastMenuType.templateMenu}
              onSidebarToggle={handleDraggableSidebarEvent}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateTextEditor: state.books.bookleTemplateTextEditor,
});

const mapDispatchToProps = {
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastEditor);
