import { useEffect, useState } from 'react';
import s from './Domains.module.scss';
import { RootState } from '../../store/rootStore';
import { connect } from 'react-redux';
import { IDomain, IGeneralProject, IGeneralProjectName } from 'types';
import {
  fetchDomains,
  addDomain,
  NOT_AT_ALL_STATUS,
  WOO_HOO_STATUS,
  ALREADY_EXISTS_STATUS,
  ADDING_DOMAIN_STATUS,
  NEUTRAL_STATUS,
  setDomainAddingStatus,
  selectFunnel,
  clearDomainError,
} from '../../store/domains/domainsActions';
import {
  fetchFunnelsAction,
  fetchFunnelsNameAction,
} from '../../store/projects/projectsActions';
import Button from '../Common/DomainButton/DomainButton';
import AddDomainPopup from './AddDomainPopup';
import AlreadyExistsPopup from './AlreadyExistsPopup';
import NotAtAllPopup from './NotAtAllPopup';
import WooHooPopup from './WooHooPopup';
import DomainItem from './DomainItem';
import DomainHasBeenDeletePopup from './DomainHasBeenDeletePopup';
import ErrorPopup from './DomainErrorPopup';
import ButtonUI from 'UILib/Button/Button';

interface IProps {
  activeProject: IGeneralProject | null;
  projects: IGeneralProjectName[];
  domains: IDomain[];
  isLoading: boolean;
  isDomainBeenDelete: boolean;
  getProjects: (id: string) => void;
  fetchDomains: () => void;
  getProjectsName: () => void;
  addingDomainStatus: string;
  setDomainAddingStatus: (status: string) => void;
  selectFunnel: (id: string, projectId: string) => void;
  addDomain: (name: string, projectId?: string) => void;
  domainError: string | null;
  clearDomainError: () => void;
}

const Domains = (props: IProps) => {
  const [userEmail, setUserEmail] = useState('');
  const [domainName, setDomainName] = useState('');
  const [
    isDomainBeenDeletePopupOpen,
    setIsDomainBeenDeletePopupOpen,
  ] = useState(false);

  const handleAddDomain = (domainName: string) => {
    if (domainName.indexOf('http') === 0) {
      domainName = domainName.replace(/http\w?:\/\//, '');
    }
    setDomainName(domainName.toLowerCase());
  };

  useEffect(() => {
    if (domainName) {
      props.addDomain(domainName);
    }
  }, [domainName]);

  useEffect(() => {
    if (props.isDomainBeenDelete) {
      setIsDomainBeenDeletePopupOpen(true);
    } else {
    }
  }, [props.isDomainBeenDelete]);

  const handleClosePopup = () => {
    props.setDomainAddingStatus(NEUTRAL_STATUS);
  };

  const handleAssociateFunnel = (funnelId: string) => {
    props.selectFunnel(props.domains[props.domains.length - 1]._id, funnelId);
    props.setDomainAddingStatus(NEUTRAL_STATUS);
  };

  useEffect(() => {
    const storedUserEmail = localStorage.getItem('USER_EMAIL');
    if (storedUserEmail) {
      setUserEmail(storedUserEmail);
    }
    props.getProjectsName();
    props.fetchDomains();
  }, []);

  return (
    <div className={s.domainsWrapper}>
      <div style={{ height: '100%' }}>
        {props.domains.length > 0 && (
          <h2 className={s.domainsContentHeader}>My Domains</h2>
        )}
        {props.domains.length === 0 && (
          <div className={s.addFirstDomainWrapper}>
            <div className={s.addFirstDomainContainer}>
              <h2 className={s.domainsContentHeader}>My Domains</h2>
              <p className={s.domainsContentText}>
                Your account has no connected domains. You can connect the first
                one here:
              </p>
              <div className={s.addFirstDomainBtnBlock}>
                <ButtonUI
                  width={200}
                  className={s.addFirstDomainBtn}
                  onClick={() => {
                    props.setDomainAddingStatus(ADDING_DOMAIN_STATUS);
                    setDomainName('');
                  }}
                >
                  + Add First Domain
                </ButtonUI>
              </div>
            </div>
          </div>
        )}

        {props.domains.length > 0 && (
          <>
            <div className={s.addNewDomainBtnBlock}>
              <Button
                name="+ Add New Domain"
                onClick={() => {
                  props.setDomainAddingStatus(ADDING_DOMAIN_STATUS);
                  setDomainName('');
                }}
                borderRadius={25}
              />
            </div>
            <div className={s.addNewDomainBtnBlockMobile}>
              <Button
                name="+ Add New Domain"
                onClick={() => {
                  props.setDomainAddingStatus(ADDING_DOMAIN_STATUS);
                  setDomainName('');
                }}
                borderRadius={25}
              >
                111
              </Button>
            </div>
            <div className={s.domainItemsBlockWrapper}>
              <div className={s.domainItemHeaderBlock}>
                <div>Domain Name</div>
                <div>Status</div>
                <div>Associated Funnel</div>
                <div></div>
              </div>
              <div>
                {props.domains.map((domain: IDomain) => (
                  <DomainItem
                    key={domain._id}
                    name={domain.name}
                    status={domain.status}
                    projectId={domain.projectId ? domain.projectId : undefined}
                    userId={domain.userId}
                    id={domain._id}
                    funnels={props.projects}
                    isLoading={props.isLoading}
                  ></DomainItem>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <AddDomainPopup
        onSubmit={(values) => handleAddDomain(values.domainName)}
        open={props.addingDomainStatus === ADDING_DOMAIN_STATUS}
        onClose={() => handleClosePopup()}
        isLoading={props.isLoading}
      />
      <AlreadyExistsPopup
        domainName={domainName}
        open={props.addingDomainStatus === ALREADY_EXISTS_STATUS}
        onClose={() => handleClosePopup()}
      />
      <NotAtAllPopup
        open={props.addingDomainStatus === NOT_AT_ALL_STATUS}
        onClose={() => handleClosePopup()}
      />
      <WooHooPopup
        open={props.addingDomainStatus === WOO_HOO_STATUS}
        onClose={() => handleClosePopup()}
        domainName={domainName}
        funnels={props.projects}
        onAssociate={handleAssociateFunnel}
      />
      <DomainHasBeenDeletePopup
        isOpen={isDomainBeenDeletePopupOpen}
        onClose={() => setIsDomainBeenDeletePopupOpen(false)}
      />
      <ErrorPopup
        error={props.domainError}
        onClose={() => props.clearDomainError()}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  projects: state.projects.projectsName,
  activeProject: state.projects.active,
  domains: state.domains.items,
  addingDomainStatus: state.domains.status,
  isLoading: state.domains.loading,
  isDomainBeenDelete: state.domains.isDomainBeenDelete,
  domainError: state.domains.error,
});

const mapDispatchToProps = {
  getProjects: (id: string) => fetchFunnelsAction(id),
  fetchDomains: () => fetchDomains(),
  addDomain: (name: string, projectId?: string) => addDomain(name, projectId),
  selectFunnel: (id: string, projectId: string) => selectFunnel(id, projectId),
  setDomainAddingStatus: (status: string) => setDomainAddingStatus(status),
  getProjectsName: () => fetchFunnelsNameAction(),
  clearDomainError: () => clearDomainError(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Domains);
