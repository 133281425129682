import { BookleTemplateBlock } from 'types';
import SectionBlock from './SectionBlock';

import styles from './SectionBlock.module.scss';

const SectionBlocks = ({ item }: { item: BookleTemplateBlock }) => {
  return (
    <div className={styles.container}>
      {item?.items?.map((e: any) => {
        return (
          <SectionBlock
            item={e}
            sectionsCount={item?.items?.length || 1}
            key={e.id}
          />
        );
      })}
    </div>
  );
};

export default SectionBlocks;
