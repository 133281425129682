import clsx from 'clsx';
import { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { ReactComponent as ImageIcon } from 'Assets/icons/image.svg';
import { ReactComponent as AlignLeft } from 'Assets/icons/toolbar/align-left.svg';
import { ReactComponent as AlignRight } from 'Assets/icons/toolbar/align-right.svg';
import { ReactComponent as AlignCenter } from 'Assets/icons/toolbar/align-center.svg';
import { AlignmentEnum, BookleTemplateBlock, PageContainerState } from 'types';
import { findItemById } from '../../utils';
import Input from 'UILib/Input/Input';
import ToggleSwitch from 'UILib/ToggleSwitch/ToggleSwitch';
import ColorSelector from 'Components/ColorSelector/ColorSelector';
import ControlInput from './ControlInput';

import styles from './ContainerStyleBlock.module.scss';

const styleKeyMap: { [key: string]: string } = {
  pt: 'paddingTop',
  pl: 'paddingLeft',
  pr: 'paddingRight',
  mb: 'marginBottom',
  mt: 'marginTop',
  mr: 'marginRight',
  ml: 'marginLeft',
};

const alignButtons = [
  {
    key: AlignmentEnum.LEFT,
    icon: <AlignLeft />,
  },
  {
    key: AlignmentEnum.CENTER,
    icon: <AlignCenter />,
  },
  {
    key: AlignmentEnum.RIGHT,
    icon: <AlignRight />,
  },
];

interface IProps {
  item: BookleTemplateBlock;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  showAlignment?: boolean;
}

const ContainerStyleBlock = ({
  item,
  templateBlocks,
  updateBlocks,
  showAlignment,
}: IProps) => {
  const itemStylesStpacing = item.styles?.blockSpacing;
  const [blockSizes, setBlockSizes] = useState<PageContainerState>({
    pl: itemStylesStpacing?.paddingLeft || 0,
    pr: itemStylesStpacing?.paddingRight || 0,
    pt: itemStylesStpacing?.paddingTop || 0,
    mb: itemStylesStpacing?.paddingTop || 0,
    mt: itemStylesStpacing?.marginTop || 0,
    mr: itemStylesStpacing?.marginRight || 0,
    ml: itemStylesStpacing?.marginLeft || 0,
  });

  const handleChangeBlockSizes = (key: string, value: string) => {
    const newArray = [...templateBlocks];
    const textItem: BookleTemplateBlock = findItemById(
      newArray,
      item.id as string
    );
    const styleKey = styleKeyMap[key];

    textItem.styles = {
      ...textItem.styles,
      blockSpacing: {
        ...textItem.styles?.blockSpacing,
        [styleKey]: parseInt(value, 10),
      },
    };

    setBlockSizes((prevState) => ({
      ...prevState,
      [key]: parseInt(value, 10),
    }));

    updateBlocks(newArray);
  };

  const handleChangeColor = (color: string) => {
    const newArray = [...templateBlocks];
    const textItem: BookleTemplateBlock = findItemById(
      newArray,
      item.id as string
    );
    textItem.color = color;

    updateBlocks(newArray);
  };

  const onUpdateItem = <K extends keyof BookleTemplateBlock>(
    key: K,
    value: BookleTemplateBlock[K]
  ) => {
    const newArray = [...templateBlocks];
    const textItem: BookleTemplateBlock = findItemById(
      newArray,
      item.id as string
    );

    if (key === 'alignment') {
      let spacingKey = '';
      value === AlignmentEnum.LEFT
        ? (spacingKey = 'ml')
        : value === AlignmentEnum.RIGHT && (spacingKey = 'mr');

      if (!!spacingKey) {
        textItem.styles = {
          ...textItem.styles,
          blockSpacing: {
            ...textItem.styles?.blockSpacing,
            [styleKeyMap[spacingKey]]: 0,
          },
        };

        setBlockSizes({ ...blockSizes, [spacingKey]: 0 });
      }
    }

    textItem[key] = value;

    updateBlocks(newArray);
  };

  return (
    <div className={styles.container}>
      <div className={clsx(styles.colorContainer, styles.contentContainer)}>
        <div className={styles.toggleContainer}>
          <div className={styles.title}>Background</div>
          <ToggleSwitch
            isBinary
            checkValue={item.hasColor}
            onToggle={(value) => onUpdateItem('hasColor', value)}
          />
        </div>
        {item.hasColor && (
          <ColorSelector
            onChange={handleChangeColor}
            color={item.color || '#fff'}
            pickerClassName={styles.colorPicker}
          />
        )}
      </div>
      {showAlignment && (
        <div className={styles.contentContainer}>
          <div className={styles.title}>Alignment</div>
          <div className={styles.alignContainer}>
            {alignButtons.map((button) => (
              <div
                className={clsx(styles.alignButton, {
                  [styles.activeAlignButton]: item.alignment === button.key,
                })}
                onClick={() => onUpdateItem('alignment', button.key)}
              >
                {button.icon}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={styles.contentContainer}>
        <div className={styles.toggleContainer}>
          <div className={styles.title}>Inner Padding (px)</div>
          <ToggleSwitch
            isBinary
            checkValue={item.hasSpacing}
            onToggle={(value) => onUpdateItem('hasSpacing', value)}
          />
        </div>
        {item.hasSpacing && (
          <div className={styles.paddingInputContainer}>
            {(['pl', 'pt', 'pr'] as Array<keyof PageContainerState>).map(
              (side) => (
                <Input
                  key={side}
                  type="number"
                  min={0}
                  className={styles.paddingInput}
                  value={blockSizes[side] as any}
                  onChange={(e) => handleChangeBlockSizes(side, e.target.value)}
                  hideArrows
                />
              )
            )}
          </div>
        )}
      </div>
      {item.hasSpacing && (
        <div className={styles.marginContainer}>
          <ControlInput
            type="horizontal"
            value={blockSizes.mt}
            handleChange={(value) => handleChangeBlockSizes('mt', value)}
          />
          <div className={styles.horizontalMargin}>
            <ControlInput
              type="vertical"
              value={blockSizes.ml}
              handleChange={(value) => handleChangeBlockSizes('ml', value)}
              disabled={item.alignment === AlignmentEnum.LEFT}
            />
            <div className={styles.image}>
              <ImageIcon />
            </div>
            <ControlInput
              type="vertical"
              value={blockSizes.mr}
              handleChange={(value) => handleChangeBlockSizes('mr', value)}
              disabled={item.alignment === AlignmentEnum.RIGHT}
            />
          </div>
          <ControlInput
            type="horizontal"
            value={blockSizes.mb}
            handleChange={(value) => handleChangeBlockSizes('mb', value)}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerStyleBlock);
